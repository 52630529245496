<template>
  <!-- LINK ICONS BOOTSTRAP -->
  <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css"> -->

  <!-- NAVBAR -->
  <!-- <div class="text-center p-1 bg-verde text-white" v-if="userLogged">
    <i class="fa-thin fa-hand-wave"></i> Bienvenido de nuevo {{this.nombreComercial}} !
   </div> -->

  <!-- NAV 2024 -->
  <!-- <div class="px-3 py-2 bg-verde-decoman text-white">
    <div class="container">
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <a href="/" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
          <img src="@/assets/decoman.png" alt="">
        </a>

        <ul class="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small" v-if="userLogged">
          <li>
            <router-link to="/">
              <a href="#" class="nav-link text-white">
                <img src="@/assets/dashboardwhite.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Dashboard
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/clientes">
              <a href="#" class="nav-link text-white">
                <img src="@/assets/users.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Listado de clientes
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/pedidos">
              <a href="#" class="nav-link text-white">
                <img src="@/assets/delivery.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Localizador de pedidos
              </a>
            </router-link>
          </li>
          <li>
            <router-link to="/login" @click="logout">
              <a href="#" class="nav-link text-white"  v-if="userLogged">
                <img src="@/assets/log-out.svg" alt="Dashboard" style="width: 25px;">
                <br>
                Cerrar Sesión
              </a>
            </router-link> 
            <a href="#" class="nav-link text-white" v-if="!userLogged">
              <img src="@/assets/log-in.svg" alt="Dashboard" style="width: 25px;">
              <br>
              Acceso
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div> -->
  <!-- NAV 2024 -->

  <!-- ROUTER VIEW - se mostrara cada pagina dependiendo de donde nos encontremos -->
  <router-view/>

  <!-- FOOTER -->
  <footer class="footer">
    <p>© 2024 Decoman | Desarrollado por <a href="http://www.proynet.es/" target="_blank">Proynet</a> y  <a href="https://www.lavallweb.com/" target="_blank">Lavallweb</a>. v1.4.29</p>
  </footer>
  <!-- FOOTER -->

</template>

<script>
import auth from '../src/api/auth';
import { useMyStore } from '@/api/myStore'

export default {
  data() {
    return {
      nombreComercial: "",
    }
  },
  computed: {
    userLogged() {
      return auth.getUserLogged();
    },
  },
  // METODO
  methods: {
    logout() {
      auth.deleteUserLogged();
      auth.deleteComercialLogged();
      auth.deleteNombreComercialLogged();
    }
  },
  async mounted() {

    // Verifica si la página se ha cargado completamente
    window.onload = () => {
      // Verifica si se está recargando la página
      if (performance.getEntriesByType("navigation")[0].type !== "navigate") {
        // Si la página se está recargando, redirige al usuario a la página de inicio
        //console.log("Ha pasado por aqui");
        this.$router.push({ path: '/' }); // Ajusta la ruta según tu configuración
      }
    };

    // OBTENER ARTICULOS
    // LO PASAMOS A MINUSCULA PARA FILTRAR MEJOR
    let myStore = useMyStore();
    // OBTENEMOS ID DEL COMERCIAL QUE LO TENEMOS GUARDADO EN UNA COOKIE
    let idComercialVentas = auth.getComercialLogged(); //ID del Comercial guardado en una Cookie
    console.log("ID Comercial " + idComercialVentas);

    //NOMBRE COMERCIAL
    this.nombreComercial = auth.getNombreComercialLogged();

    // OBTENER CLIENTES
    for (let index = 0; index < 1000; index++) {
      //Comprobamos si la página tiene registros. En el momento que no hayan registros, el for se para)
      var respuestaCantidadRegistrosClientes = await auth.getObtenerTodosLosContactosDelComercial(index, idComercialVentas);
      let quedanRegistrosClientes;
      respuestaCantidadRegistrosClientes.data.count == 0 ? quedanRegistrosClientes = false : quedanRegistrosClientes = true;

      if (!quedanRegistrosClientes) break;

      respuestaCantidadRegistrosClientes.data.ent_m.forEach(art => {
        myStore.clientes.push(art);
      });
    }

    myStore.estadoClientes = true;
    console.log("Todos los clientes han sido obtenidos, terminado");

    // HACEMOS LA CONSULTA PARA OBTENER ARTICULOS
    for (let index = 0; index < 10000; index++) {
      //Comprobamos si la página tiene registros. En el momento que no hayan registros, el for se para)
      var respuestaCantidadRegistros = await auth.getObtenerTodosLosArticulos(index);
      let quedanRegistros;
      respuestaCantidadRegistros.data.count == 0 ? quedanRegistros = false : quedanRegistros = true;

      if (!quedanRegistros) break;

      respuestaCantidadRegistros.data.art_m.forEach(art => {
        // SI EL ARTICULO NO ESTA VISIBLE EN WEB NO LO AÑADIMOS
        if (art.web_vis == true) {
          myStore.articulos.push(art);
        }
        // articulosStorage.push(art)
      });

      // console.log("Articulos obtenidos " + index);
    }

    myStore.estadoArticulos = true;
    console.log("Todos los articulos han sido obtenidos, terminado");
    console.log(myStore.articulos);
  }

};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a {
  color: #ffffff !important;
  text-decoration: inherit !important;
}

nav {
  padding: 30px;
}

nav a.router-link-exact-active {
  color: white;
}

/* ICONOS */
::before {
    padding-bottom: 3%;
}

.bg-verde {
  background-color: #7FCC72;
}

.bg-verde-decoman {
  background-color: #2c8a8d;
}

.footer {
  background-color: #f2f2f2;
  padding: 20px;
  text-align: center;
}

.footer p {
  color: #000000 !important;
}

.footer a {
  color: #318a8d !important;
}


</style>